/* latin-ext */
@font-face {
	font-family: 'Lekton';
	font-style: normal;
	font-weight: 400;
	src: local('Lekton'), local('Lekton-Regular'), url(/assets/fonts/SZc43FDmLaWmWpBuVh3pv0Db6AtO.woff2) format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'Lekton';
	font-style: normal;
	font-weight: 400;
	src: local('Lekton'), local('Lekton-Regular'), url(/assets/fonts/SZc43FDmLaWmWpBuWB3pv0Db6A.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	src: local('Poppins SemiBold'), local('Poppins-SemiBold'), url(/assets/fonts/pxiByp8kv8JHgFVrLEj6Z11lFd2JQEl8qw.woff2) format('woff2');
	unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	src: local('Poppins SemiBold'), local('Poppins-SemiBold'), url(/assets/fonts/pxiByp8kv8JHgFVrLEj6Z1JlFd2JQEl8qw.woff2) format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	src: local('Poppins SemiBold'), local('Poppins-SemiBold'), url(/assets/fonts/pxiByp8kv8JHgFVrLEj6Z1xlFd2JQEk.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}